#icons {
    height: 90%;
}

.button {
    min-height: 50px;
    height: 20%;
    width: 15%;
    background: #606f8552 !important;
}

#linkedin {
    background: linear-gradient(white, white) no-repeat;
    background-position: center;
    background-size: 60% 60%;
}