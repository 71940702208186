#pic {
    width: 90%;
    box-shadow: 11px 15px 40px rgba(50, 51, 61, 0.5);
    border-radius: 8px;
    margin-right: 30px;
}

#logo {
    width: 5%;
    margin-left: 7px;
    vertical-align: middle;
    padding-bottom: 2px;
    background: linear-gradient(white, white) no-repeat;
    background-position: center;
    background-size: 75% 75%;
}

@media only screen and (max-width: 575px) {
    #pic {
        margin-bottom: 30px;
        margin-right: 0px;
        width: 70%;
    }
    #container {
        text-align: center !important;
        width: 90%;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    hr {
        float: none !important;
    }
}

#container {
    background-image: url("./res/paper_texture.png"); /* credit to https://www.transparenttextures.com/ for texture */
    background-color: #5D6A7E;
    border: 2px solid;
    border-image: radial-gradient(rgb(48, 47, 28), rgb(30, 42, 59)) 1;
    text-align: right;
    max-width: 800px;
    padding: 40px;
    min-width: 250px;
}

hr {
    width: 95%;
    float: right;
    border: 1px solid rgb(55, 60, 80);
    background-color: rgb(55, 60, 80);
    height: 3px;
    opacity: 1 !important;
}

h1 {
    font-size: min(5vw, 30pt);
    color:white;
}

p {
    font-size: min(3.5vw, 16pt);
    color:#b9c1cf;
}